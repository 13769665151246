.partners {
  padding-top: 48px;
  height: 468px;
  background-image: url("../../assets/partners.png");
  background-repeat: no-repeat;
  position: relative;


  @media (max-width: 450px) {
    height: 600px;
    background-size: cover;
  }

  &-title {
    margin: 0 0 0 85px;
    font-family: "Navigo Medium";
    font-weight: 200;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.02em;
    color: #212721;

    @media (max-width: 450px) {
      margin: 0 0 0 16px;
    }
  }

  &-brands {
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 450px) {
      flex-flow: column;
      margin-top: 40px;
    }
  }
}

.huawei {
  @media (max-width: 900px) {
    width: 200px;
  }

  @media (max-width: 770px) {
    width: 180px;
  }
}

.lenovo {
  margin-left: 129px;

  @media (max-width: 900px) {
    width: 400px;
  }

  @media (max-width: 770px) {
    width: 350px;
  }

  @media (max-width: 450px) {
    width: 280px;
    margin-left: 0;
  }
}