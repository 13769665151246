.y-map {
  height: 460px;
  width: 1110px;

  @media (max-width: 1280px) {
    width: calc(750px + (1110 - 750) * ((100vw - 800px) / (1280 - 800)));
  }

  @media (max-width: 450px) {
    width: 100%;
    height: 410px;
  }
}