body {
  margin: 0;
  padding: 0;
  background-color: #000;
  box-sizing: border-box;
  font-family: -apple-system, "Navigo Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Navigo Regular", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "TT Firs Neue Regular";
  src: url("./assets/fonts/TTFirsNeue-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Navigo Thin";
  src: url("./assets/fonts/Navigo-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Navigo Light";
  src: url("./assets/fonts/Navigo-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "Navigo Regular";
  src: url("./assets/fonts/Navigo-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Navigo Medium";
  src: url("./assets/fonts/Navigo-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Navigo Bold";
  src: url("./assets/fonts/Navigo-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}