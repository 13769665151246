.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;

  &__body {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .form-wrapper {
      background-color: #fff;
      width: 443px;
      height: 770px;
      padding: 32px 40px;

      @media (max-height: 768px), (max-width: 450px) {
        width: 300px;
        height: fit-content;
        padding: 16px 20px;
      }


      span {
        display: inline-block;
        margin-top: 24px;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.002em;
        color: #212721;
        opacity: 0.7;
      }

      .form-title {
        margin: 0;
        font-family: "Navigo Regular";
        font-weight: 200;
        font-size: 20px;
        line-height: 14px;
        letter-spacing: 0.002em;
        color: #212721;

        @media (max-height: 768px), (max-width: 450px) {
          font-size: 16px;
        }
      }

      .form {
        margin-top: 32px;

        @media (max-height: 768px), (max-width: 450px) {
          margin-top: 16px;
        }

        &-item {
          margin-top: 16px;
          display: flex;
          flex-flow: column;
          font-size: 10px;
          font-family: "Navigo Medium";
          font-weight: 200;
          line-height: 14px;
          letter-spacing: 0.002em;
          text-transform: uppercase;
          color: #212721;

          @media (max-height: 768px), (max-width: 450px) {
            margin-top: 12px;
            font-size: 8px;
          }

          &:first-child {
            margin-top: 0;
          }

          input {
            margin-top: 8px;
            padding: 0 14px;
            height: 42px;
            opacity: 0.7;
            border: 1px solid #768199;
            border-radius: 8px;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.002em;
            color: #212721;

            @media (max-height: 768px), (max-width: 450px) {
              margin-top: 6px;
              font-size: 10px;
              height: 26px;
            }
          }

          textarea {
            margin-top: 8px;
            padding: 14px 14px 0 14px;
            height: 96px;
            opacity: 0.7;
            border: 1px solid #768199;
            border-radius: 8px;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.002em;
            color: #212721;
            resize: none;

            @media (max-height: 768px), (max-width: 450px) {
              margin-top: 6px;
              font-size: 10px;
              height: 46px;
              padding: 6px 6px 0 6px;
            }
          }

          &-captcha {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            img {
              height: 42px;
            }

            button {
              all: unset;
              cursor: pointer;
              font-size: 26px;
            }

            input {
              width: 100%;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.formBtn {
  margin-top: 24px;
  background:
          linear-gradient(#fff, #fff) padding-box,
          linear-gradient(221.71deg, #2DC3FF 26.59%, #A85CFD 76.53%) border-box;
  width: 163px;
  height: 48px;
  border: 1px solid transparent;
  border-radius: 100px;
  font-family: 'Navigo Regular';
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 0.002em;
  color: #212721;
  cursor: pointer;

  @media (max-height: 768px), (max-width: 450px){
    height: 32px;
    width: 100px;
    font-size: 12px;
  }

  &:hover {
    background: linear-gradient(221.71deg, #2DC3FF 26.59%, #A85CFD 76.53%) border-box;
    border: none;
  }
}