.contacts {
  height: 712px;
  padding-top: 48px;
  //height: 666px;
  //padding: 48px 85px 46px;
  background-color: #212721;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-flow: column;

  @media (max-width: 450px) {
    height: 774px;
    padding: 56px 16px 0;
  }

  &-title {
    width: 1110px;
    margin: 0 0 32px 0;
    font-family: "Navigo Light";
    font-weight: 200;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.02em;
    color: #FFFFFF;

    @media (max-width: 1280px) {
      width: calc(750px + (1110 - 750) * ((100vw - 800px) / (1280 - 800)));
    }

    @media (max-width: 450px) {
      width: 100%;
    }
  }

  &-footer {
    width: 1110px;
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: end;

    @media (max-width: 1280px) {
      width: calc(750px + (1110 - 750) * ((100vw - 800px) / (1280 - 800)));
    }

    @media (max-width: 450px) {
      width: 100%;
      flex-flow: column;
      margin-top: 24px;
    }

    .footer-data {
      display: flex;

      @media (max-width: 450px) {
        flex-flow: column;
        width: 100%;
      }

      .address {

        @media (max-width: 450px) {
          width: 100%;
        }
        &-title {
          margin: 0 0 8px 0;
          font-family: "Navigo Regular";
          font-weight: 300;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 0.002em;
          color: #FFFFFF;

          @media (max-width: 450px) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        &-text {
          margin: 0;
          width: 280px;
          font-family: "Navigo Thin";
          font-weight: 100;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 0.002em;
          color: #FFFFFF;

          @media (max-width: 800px) {
            width: calc(200px + (280 - 200) * ((100vw - 600px) / (800 - 600)));
          }

          @media (max-width: 450px) {
            width: 100%;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .email {
        margin-left: 55px;

        @media (max-width: 450px) {
          margin-left: 0;
          margin-top: 16px;
        }
      }
    }
  }
}

.footerBtn{
  background:
          linear-gradient(#212721, #212721) padding-box,
          linear-gradient(221.71deg, #2DC3FF 26.59%, #A85CFD 76.53%) border-box;
  width: 201px;
  height: 48px;
  border: 1px solid transparent;
  border-radius: 100px;
  font-family: 'Navigo Light';
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 0.002em;
  color: #FFFFFF;
  cursor: pointer;

  @media (max-width: 450px) {
    margin-top: 40px;
  }

  &:hover {
    background: linear-gradient(221.71deg, #2DC3FF 26.59%, #A85CFD 76.53%);
    border: none;
  }
}