.header-wrapper {
  height: 672px;
  background-image: url("../../assets/about.png");
  background-repeat: no-repeat;
  position: relative;

  @media (max-width: 820px) {
    height: 500px;
  }

  @media (max-width: 750px) {
    height: 400px;
  }

  @media (max-width: 450px) {
    height: 600px;
  }

  .header {
    width: calc(100% - 170px);
    height: 30px;
    background: rgba(32, 34, 45, 0.5);
    backdrop-filter: blur(6px);
    padding: 34px 85px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 820px) {
      padding: 20px 50px 18px;
      width: calc(100% - 100px);
    }

    @media (max-width: 750px) {
      padding: 10px 10px 10px;
      width: calc(100% - 20px);
    }

    @media (max-width: 450px) {
      background-color: transparent;
      backdrop-filter: none;
      padding: 16px 16px 10px;
      width: calc(100% - 32px);
    }

    h1 {
      font-family: "TT Firs Neue Regular", sans-serif;
      color: #FFF;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      letter-spacing: 0.05em;
      line-height: 14px;

      @media (max-width: 1110px) {
        font-size: 20px;
      }

      @media (max-width: 930px) {
        font-size: 18px;
      }

      @media (max-width: 450px) {
        font-size: 14px;
      }
    }

    .burger {
      width: 32px;
      height: 32px;
      cursor: pointer;
      display: none;

      @media (max-width: 450px) {
        display: block;
      }
    }

    &-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      list-style: none;

      @media (max-width: 450px) {
        display: none;
      }

      li{
        margin-right: 48px;
        cursor: pointer;

        @media (max-width: 1110px) {
          margin-right: 30px;
        }

        @media (max-width: 930px) {
          margin-right: 20px;
        }

        a {
          color: #FFFFFF;
          text-decoration: none;
          font-family: "Navigo Light", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 14px;
          letter-spacing: 0.002em;
          opacity: 0.7;

          @media (max-width: 1110px) {
            font-size: 14px;
          }

          @media (max-width: 930px) {
            font-size: 12px;
          }
        }

        &:nth-last-child(1) {
          margin-right: 0;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .about-wrapper {
    display: flex;
    align-items: end;
    justify-content: space-between;
    width: calc(100% - 170px);
    position: absolute;
    bottom: 92px;
    padding: 0 85px;

    @media (max-width: 820px) {
      padding: 0 50px;
      width: calc(100% - 100px);
      bottom: 50px;
    }

    @media (max-width: 750px) {
      padding: 0 10px;
      width: calc(100% - 20px);
      bottom: 30px;
    }

    @media (max-width: 450px) {
      padding: 0 17px;
      width: calc(100% - 34px);
      display: flex;
      flex-flow: column;
    }

    .about {

      &-head {
        margin: 0 0 32px 0;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 14px;
        letter-spacing: 0.002em;
        color: #F4F4F4;

        @media (max-width: 1110px) {
          font-size: 20px;
        }

        @media (max-width: 930px) {
          font-size: 18px;
        }

        @media (max-width: 820px) {
          font-size: 16px;
        }

        @media (max-width: 750px) {
          font-size: 14px;
        }

        @media (max-width: 450px) {
          font-size: 20px;
        }
      }

      &-text {
        margin: 0;
        width: 638px;
        //height: 169px;
        font-family: "Navigo Light";
        font-size: 24px;
        line-height: 34px;
        letter-spacing: 0.002em;
        color: #FFFFFF;

        @media (max-width: 1110px) {
          width: 550px;
          font-size: 20px;
          line-height: 28px;
        }

        @media (max-width: 930px) {
          width: 450px;
          font-size: 18px;
        }

        @media (max-width: 820px) {
          width: 400px;
          font-size: 16px;
          line-height: 22px;
        }

        @media (max-width: 750px) {
          width: 350px;
          font-size: 14px;
        }

        @media (max-width: 750px) {
          width: 100%;
          font-size: 18px;
          line-height: 25px;
        }

        span {
          font-family: "Navigo Medium";
        }
      }
    }
  }
}

.mobile {
  cursor: pointer;
  height: 568px;
  background-color: #272936;
  padding: 16px 16px;

  &-close {
    height: 20px;
    text-align: right;
  }

  &-list {
    margin-top: 30px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        a {
          margin-bottom: 17px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 17px;
          border-bottom: 1px solid #768199;
          font-family: 'Navigo Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 14px;
          letter-spacing: 0.002em;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  &-feedback {
    display: inline-block;
    margin-top: 15px;
    font-family: 'Navigo Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 14px;
    letter-spacing: 0.002em;
    color: #fff;
  }
}

.headerBtn {
  background:
          linear-gradient(#000, #000) padding-box,
          linear-gradient(221.71deg, #2DC3FF 26.59%, #A85CFD 76.53%) border-box;
  width: 201px;
  height: 48px;
  border: 1px solid transparent;
  border-radius: 100px;
  font-family: 'Navigo Light';
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 0.002em;
  color: #FFFFFF;
  cursor: pointer;

  @media (max-width: 1110px) {
    width: 150px;
    font-size: 14px;
  }

  @media (max-width: 930px) {
    width: 120px;
    height: 36px;
    font-size: 12px;
  }

  @media (max-width: 820px) {
    width: 110px;
    font-size: 12px;
  }

  @media (max-width: 450px) {
    margin-top: 40px;
    width: 201px;
    height: 48px;
    font-size: 16px;
  }

  &:hover {
    background: linear-gradient(221.71deg, #2DC3FF 26.59%, #A85CFD 76.53%);
    border: none;
  }
}