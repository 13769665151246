.areas {
  height: 664px;
  background-image: url("../../assets/areas.png");
  background-repeat: no-repeat;
  position: relative;
  //padding: 48px 85px;
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 85px;
  padding-right: 85px;

  @media (max-width: 1280px) {
    padding-left: calc(20px + (85 - 20) * ((100vw - 800px) / (1280 - 800)));
    padding-right: calc(20px + (85 - 20) * ((100vw - 800px) / (1280 - 800)));
    height: calc(600px + (664 - 600) * ((100vw - 800px) / (1280 - 800)));
  }

  @media (max-width: 930px) {
    height: 500px;
    padding-top: 30px;
  }

  @media (max-width: 830px) {
    height: 440px;
  }

  @media (max-width: 450px) {
    min-height: 524px;
    height: fit-content;
    padding: 56px 16px 0;
  }


  &-title {
    margin: 0 0 32px 0;
    font-family: "Navigo Light";
    font-weight: 200;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.02em;
    color: #FFFFFF;

    @media (max-width: 1000px) {
      font-size: 20px;
      margin-bottom: 20px;
    }

    @media (max-width: 900px) {
      font-size: 18px;
      margin-bottom: 16px;
    }

    @media (max-width: 900px) {
      font-size: 16px;
      margin-bottom: 12px;
    }

    @media (max-width: 450px) {
      font-size: 20px;
      margin-bottom: 40px;
    }
  }

  &-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    //margin-top: 32px;
    padding: 48px 56px;
    width: inherit;
    height: 500px;
    background: #FFFFFF;
    border-radius: 8px;

    @media (max-width: 1280px) {
      padding-top: calc(10px + (48 - 10) * ((100vw - 800px) / (1280 - 800)));
      padding-bottom: calc(10px + (48 - 10) * ((100vw - 800px) / (1280 - 800)));
      padding-right: calc(10px + (56 - 10) * ((100vw - 800px) / (1280 - 800)));
      padding-left: calc(10px + (56 - 10) * ((100vw - 800px) / (1280 - 800)));
    }

    @media (max-width: 930px) {
      height: 430px;
    }

    @media (max-width: 830px) {
      height: 400px;
    }

    @media (max-width: 450px) {
      min-height: 408px;
      height: fit-content;
      width: calc(100% - 30px);
      padding: 0 15px;
      justify-content: normal;
      flex-flow: column;
      margin-bottom: 16px;
    }

    &__item {

      @media (max-width: 1280px) {
        width: calc(300px + (400 - 300) * ((100vw - 800px) / (1280 - 800)));
      }

      @media (max-width: 450px) {
        display: flex;
        flex-flow: column;
        justify-content: left;
        //align-items: center;
        width: 100%;
        height: fit-content;
        padding-bottom: 12px;
        border-bottom: 1px solid #768199;
        margin-top: 40px;
      }

      &:nth-child(1) {
        margin-bottom: 40px;

        @media (max-width: 1280px) {
          //margin-right: calc(40px + (80 - 40) * ((100vw - 800px) / (1280 - 800)));
        }
        @media (max-width: 450px) {
          margin-bottom: 0;
        }
      }

      &:nth-last-child(1){
        margin-bottom: 20px;
      }

      .title-block {

        display: flex;
        align-items: center;

        .item-title {
          width: 450px;
          font-family: "Navigo Light";
          margin: 0;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 0.002em;
          color: #212721;

          @media (max-width: 1280px) {
            width: calc(330px + (450 - 330) * ((100vw - 800px) / (1280 - 800)));
            font-size: 16px;
            line-height: 20px;
          }

          @media (max-width: 930px) {
            font-size: 14px;
            line-height: 16px;
          }

          @media (max-width: 830px) {
            font-size: 12px;
            line-height: 12px;
          }

          @media (max-width: 450px) {
            font-size: 14px;
            line-height: 20px;
            width: 100%;
            margin-left: 8px;
          }
        }
      }

      .item-text {
        font-family: "Navigo Light";
        width: 450px;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.002em;
        color: #212721;
        opacity: 0.7;

        @media (max-width: 1280px) {
          //width: calc(330px + (450 - 330) * ((100vw - 800px) / (1280 - 800)));
          font-size: 16px;
          line-height: 20px;
          width: fit-content;
        }

        @media (max-width: 930px) {
          font-size: 14px;
          line-height: 16px;
        }

        @media (max-width: 830px) {
          font-size: 12px;
          line-height: 12px;
        }

        @media (max-width: 450px) {
          margin: 0;
          width: 100%;
          display: none;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .text-active {
    display: block !important;
    font-size: 13px !important;
    line-height: 18px !important;
    letter-spacing: 0.002em;
  }
}